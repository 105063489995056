html, body, #root {
    margin: 0;
    font-family: Roboto-300, sans-serif;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    scroll-behavior: smooth;
}

body::-webkit-scrollbar {
    display: none;
}

.App {
    position: relative;
    min-height: 100vh;
    overflow: hidden;
}

#invictus_loading {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20vw;
    transform: translate(-50%, -50%);
}

#invictus_loading .cls-1 {
    stroke-dasharray: 3330;
    stroke-dashoffset: 3330;
    animation: draw-trace-circle 4s ease forwards;
}

@keyframes draw-trace-circle {
    0% {
        stroke-dashoffset: 3330;
    }
    100% {
        stroke-dashoffset: 0;
    }
}

/*
  ____                _                                         _
 | __ )   __ _   ___ | | __ __ _  _ __  ___   _   _  _ __    __| |
 |  _ \  / _` | / __|| |/ // _` || '__|/ _ \ | | | || '_ \  / _` |
 | |_) || (_| || (__ |   <| (_| || |  | (_) || |_| || | | || (_| |
 |____/  \__,_| \___||_|\_\\__, ||_|   \___/  \__,_||_| |_| \__,_|
                           |___/
*/
.MainGridLayer_2 {
    position: relative;
    height: 200vh;
    width: 100vw;
    display: grid;
    grid-template-columns: 50px auto 60% 50px;
    grid-template-rows: 1% 5% 6% 17.5% 14% 3.5% auto 50%;
    gap: 1px 1px;
    z-index: 5;
    padding-bottom: 100px;
}

.BackgroundLayers {
    top: 0;
    left: 0;
    position: absolute;
    height: 100%;
    overflow: hidden;
}

.PageBackgroundBase, .PageBackgroundCutout {
    position: absolute;
    width: 100vw;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
    background-repeat: no-repeat;
    z-index: 0;
    stroke: transparent;
    stroke-width: 0;
}

.PageBackgroundCutout {
    z-index: 3;
}

.Footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100px;
    background-color: rgba(15, 32, 46, 0.7);
    color: white;
    z-index: 5;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
}

.MITLogoLink {
    position: relative;
    order: 0;
    height: 100%;
    width: fit-content;
}

.CornellTechLogoLink {
    position: relative;
    order: 1;
    height: 100%;
    width: fit-content;
}

.MITLogo, .CornellTechLogo {
    position: relative;
    height: 80%;
    top: 5%;
    left: 50%;
    transform: translateX(-50%);
    max-width: 80%;
    object-fit: contain;
}

/*
  _   _                 ____
 | \ | |  __ _ __   __ | __ )   __ _  _ __
 |  \| | / _` |\ \ / / |  _ \  / _` || '__|
 | |\  || (_| | \ V /  | |_) || (_| || |
 |_| \_| \__,_|  \_/   |____/  \__,_||_|

 */

.NavBar {
    grid-area: NavBar;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 0 5px;
    grid-template-areas: "Home AboutUs Team News ContactUs";
}

.HomeNavSection {
    grid-area: Home;
}

.AboutNavSection {
    grid-area: AboutUs;
}

.TeamNavSection {
    grid-area: Team;
}


.NewsNavSection {
    grid-area: News;
}


.ContactNavSection {
    grid-area: ContactUs;
}

.NavButton {
    position: relative;
    width: 100%;
    height: 100%;
}

.NavLinks {
    width: fit-content;
    height: fit-content;
    display: block;
    color: #065394;
    text-decoration: none;
}

.NavText {
    position: absolute;
    left: 50%;
    top: 50%;
    text-align: center;
    white-space: nowrap;
    padding: 20px;
    width: fit-content;
    font-family: Roboto-regular, sans-serif;
    font-size: 1vw;
    letter-spacing: 3px;
    word-spacing: 5px;
    border-radius: .3vw;
    background: none;
    transition: 1s;
    transform: translate(-50%, -50%);
}

.NavText:hover {
    font-family: Roboto-900, sans-serif;
    border: 1px solid #054E8A;
    background: rgba(255, 255, 255, 0.34);
    border-radius: 7vw;
    letter-spacing: 4px;
    word-spacing: 7px;
}

.NavText:active {
    border: 3px solid #065394;
}

.HomeNavSection > .NavLinks > .NavText:hover {
    background: rgba(5, 78, 138, 0.10);
    border-color: #054E8A;
    color: #054E8A;
    letter-spacing: 7px;
}

.AboutNavSection > .NavLinks > .NavText:hover {
    background: rgba(56, 86, 70, 0.10);
    border-color: #385646;
    color: #385646;
}

.TeamNavSection > .NavLinks > .NavText:hover {
    background: rgba(143, 0, 36, 0.10);
    border-color: #8F0024;
    color: #8F0024;
    letter-spacing: 7px;
}

.NewsNavSection > .NavLinks > .NavText:hover {
    background: rgba(202, 149, 2, .2);
    color: #CA9502;
    border-color: #CA9502;
    letter-spacing: 7px;
}

.ContactNavSection > .NavLinks > .NavText:hover{
    background: rgba(151, 94, 147, 0.10);
    color: #975E93;
    border-color: #975E93;
}

/*
  _           _    _
 | |     ___ | |_ | |_  ___  _ __  ___
 | |    / _ \| __|| __|/ _ \| '__|/ __|
 | |___|  __/| |_ | |_|  __/| |   \__ \
 |_____|\___| \__| \__|\___||_|   |___/

 */

.PageTitle {
    width: 100%;
    grid-area: TitleText;
}

.PageSubTitle {
    position: absolute;
    padding: 20px;
    bottom: 10%;
    left: 50%;
    width: fit-content;
    white-space: nowrap;
    transform: translateX(-50%);
    grid-area: SubTitleText;
    text-align: center;
    font-size: 1.8vw;
    letter-spacing: 2px;
    word-spacing: 2px;
    border-radius: 26px;
    transition: 3s;
    font-family: Roboto-300-italic, sans-serif;
    font-style: italic;
}

.ArrowButton {
    grid-area: Arrow;
    width: 100%;
    height: 100%;
}

.MovingDownArrow {
    position: absolute;
    height: 5vh;
    width: auto;
    left: 50%;
    transform: translateX(-50%);
    transition: .65s;
    overflow: visible;
    padding: 1vh;
}

.MovingDownArrow path {
    fill: #054E8A;
    stroke: #065394;
    stroke-width: 5px;
}

.MovingDownArrow circle {
    fill: rgba(255, 255, 255, 0.34);
    stroke: #065394;
    stroke-width: 2px;
}

.MovingDownArrow:hover {
    /*animation: moving-arrow 1s ease-in-out forwards;*/
    padding: 3.5vh 1vh 1vh;
}

.MainPageLayer > .PageSubTitle:hover {
    border-radius: 3px;
}

.MainPageLayer > .PageTitle > * {
    width: 100%;
    height: 100%;
    fill: #065394;
    stroke: none;
    stroke-width: .2vw;
    stroke-miterlimit: 10;
    overflow: visible;
}

.MainPageLayer > .PageTitle > * path {
    transition: 1s;
}

.MainPageLayer > .PageTitle:hover * path {
    animation: draw-trace 4s ease-in-out infinite;
    animation-delay: 1s;
    animation-direction: alternate-reverse;
}

.MainPageLayer > .PageTitle:active * path {
    animation-play-state: paused;
}

@keyframes draw-trace {
    from {
        stroke-dashoffset: 0;
        /*fill: rgba(8, 109, 195, 0.2);*/
    }
}


/*

  _____              _
 |  ___|___   _ __  | |_  ___
 | |_  / _ \ | '_ \ | __|/ __|
 |  _|| (_) || | | || |_ \__ \
 |_|   \___/ |_| |_| \__||___/


 */

/* roboto-100 - latin */
@font-face {
    font-family: Roboto-100;
    font-style: normal;
    font-weight: 100;
    src: local('Roboto Thin'), local('Roboto-Thin'),
    url('./fonts/roboto-v20-latin-100.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./fonts/roboto-v20-latin-100.woff') format('woff'), /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
    url('./fonts/roboto-v20-latin-100.ttf')  format('truetype'); /* Safari, Android, iOS */
}

/* roboto-300 - latin */
@font-face {
    font-family: Roboto-300;
    font-style: normal;
    font-weight: 300;
    src: local('Roboto Light'), local('Roboto-Light'),
    url('./fonts/roboto-v20-latin-300.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./fonts/roboto-v20-latin-300.woff') format('woff'), /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
    url('./fonts/roboto-v20-latin-300.ttf')  format('truetype'); /* Safari, Android, iOS */
}

/* roboto-regular - latin */
@font-face {
    font-family: Roboto-regular;
    font-style: normal;
    font-weight: 400;
    src: local('Roboto'), local('Roboto-Regular'),
    url('./fonts/roboto-v20-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./fonts/roboto-v20-latin-regular.woff') format('woff'), /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
    url('./fonts/roboto-v20-latin-regular.ttf')  format('truetype'); /* Safari, Android, iOS */
}

/* roboto-900 - latin */
@font-face {
    font-family: Roboto-900;
    font-style: normal;
    font-weight: 900;
    src: local('Roboto Black'), local('Roboto-Black'),
    url('./fonts/roboto-v20-latin-900.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./fonts/roboto-v20-latin-900.woff') format('woff'), /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
    url('./fonts/roboto-v20-latin-900.ttf')  format('truetype'); /* Safari, Android, iOS */
}

/* roboto-300italic - latin */
@font-face {
    font-family: Roboto-300-italic;
    font-style: italic;
    font-weight: 300;
    src: local('Roboto Light Italic'), local('Roboto-LightItalic'),
    url('./fonts/roboto-v20-latin-300italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./fonts/roboto-v20-latin-300italic.woff') format('woff'), /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
    url('./fonts/roboto-v20-latin-300italic.ttf')  format('truetype'); /* Safari, Android, iOS */
}
/* roboto-italic - latin */
@font-face {
    font-family: Roboto-regular-italic;
    font-style: italic;
    font-weight: 400;
    src: local('Roboto Italic'), local('Roboto-Italic'),
    url('./fonts/roboto-v20-latin-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./fonts/roboto-v20-latin-italic.woff') format('woff'), /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
    url('./fonts/roboto-v20-latin-italic.ttf')  format('truetype'); /* Safari, Android, iOS */
}
/* roboto-900italic - latin */
@font-face {
    font-family: Roboto-900-italic;
    font-style: italic;
    font-weight: 900;
    src: local('Roboto Black Italic'), local('Roboto-BlackItalic'),
    url('./fonts/roboto-v20-latin-900italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./fonts/roboto-v20-latin-900italic.woff') format('woff'), /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
    url('./fonts/roboto-v20-latin-900italic.ttf')  format('truetype'); /* Safari, Android, iOS */
}

/*
                       _  _
  _ __ ___    ___   __| |(_)  __ _
 | '_ ` _ \  / _ \ / _` || | / _` |
 | | | | | ||  __/| (_| || || (_| |
 |_| |_| |_| \___| \__,_||_| \__,_|

*/

@media only screen and (max-width: 800px) {
    .BackgroundLayers {
        position: fixed;
    }

    .PageBackgroundBase, .PageBackgroundCutout {
        position: fixed;
    }

    .MainGridLayer_2 {
        width: 100vw;
        height: 202vh;
        grid-template-columns: 6% 88% 6%;
        /*grid-template-rows: 17% 5% 10% 2.5% 10% 5.5% 50%;*/
        grid-template-rows: 20% 5% 10% 2.5% 10% 2.5% 50%;
        gap: 1px 1px;
    }

    .PageSubTitle {
        height: fit-content;
        width: 100%;
        white-space: normal;
        font-size: 1.8vh;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        padding: 15px 10px 15px;
    }

    .NavBar {
        width: 60%;
        padding-left: 20%;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr;
        /*grid-template-columns: max-content max-content;*/
        /*grid-template-rows: max-content max-content max-content;*/
        grid-template-areas: "Home Home" "AboutUs Team" "News ContactUs";
        gap: 25% 15%;
    }

    .NavLinks {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    .NavText {
        font-size: 3vw;
        border: 1px solid #054E8A;
        background: rgba(255, 255, 255, 0.34);
        border-radius: 7vw;
        letter-spacing: 4px;
        word-spacing: 7px;
        padding: 2vh;
    }

    .NavNewsSection {
        width: 72%;
    }

    .HomeNavSection > .NavLinks > .NavText{
        background: rgba(5, 78, 138, 0.10);
        border-color: #054E8A;
        color: #054E8A;
        letter-spacing: 7px;
        width: 90%;
    }

    .AboutNavSection > .NavLinks > .NavText{
        background: rgba(56, 86, 70, 0.10);
        border-color: #385646;
        color: #385646;
        transform: translate(0, -50%);
        left: 0;
    }

    .TeamNavSection > .NavLinks > .NavText {
        background: rgba(143, 0, 36, 0.10);
        border-color: #8F0024;
        color: #8F0024;
        letter-spacing: 7px;
        transform: translate(-100%, -50%);
        left: 100%;
    }

    .NewsNavSection > .NavLinks > .NavText {
        background: rgba(202, 149, 2, .2);
        color: #CA9502;
        border-color: #CA9502;
        letter-spacing: 7px;
        transform: translate(0, -50%);
        left: 0;
    }

    .ContactNavSection > .NavLinks > .NavText {
        background: rgba(151, 94, 147, 0.10);
        color: #975E93;
        border-color: #975E93;
        transform: translate(-100%, -50%);
        left: 100%;
    }

    .ArrowButton {
        visibility: hidden;
    }

    .BackgroundLayers > .AccentLayer {
        opacity: .8;
    }
}

@media only screen and (max-width: 550px) {
    .NavText {
        font-size: 4vw;
        border: 1px solid #054E8A;
        background: rgba(255, 255, 255, 0.34);
        border-radius: 7vw;
        letter-spacing: 4px;
        word-spacing: 7px;
        padding: 2vh;
    }

    .NavBar {
        width: 100%;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr;
        /*grid-template-columns: max-content max-content;*/
        /*grid-template-rows: max-content max-content max-content;*/
        grid-template-areas: "Home Home" "AboutUs Team" "News ContactUs";
        gap: 25% 15%;
        padding: 0;
    }
}